@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');

* {
    box-sizing: border-box;
    text-rendering: geometricPrecision;
    outline: 0;
}

:root {
    --global-background-color: #fbfaf8;
    --input-border-color: #dddddd;
    --box-shadow-central: 0 0 0 1px var(--input-border-color), 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
    --global-font-size-heading: 20px;
    --global-font-size: 16px;
    --global-font-size-small: 14px;
    --global-text-color: #000;
    --font: 'Nunito', sans-serif;
}

html, body, input, select, table, button {
    font-family: var(--font);
    font-size: var(--global-font-size);
    color: var(--global-text-color);
}

body {
    padding: 10px 230px 25px;
    background-color: var(--global-background-color);
}

.page {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    padding: 5px 30px 25px;
}

h1 {
    margin: 28px auto;
    display: table;
    font-weight: 800;
}

a {
    color: var(--global-text-color);
    font-family: var(--font);
}

a:hover {
    opacity: 0.5;
    cursor: pointer;
}

a:active {
    opacity: 0.4;
}

.button-74 {
    background-color: #fbeee0;
    border: 2px solid #422800;
    border-radius: 20px;
    box-shadow: #422800 3px 3px 0 0;
    color: #422800;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    padding: 0 25px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-74:hover {
    background-color: #fff;
}

.button-74:active {
    box-shadow: #422800 2px 2px 0 0;
    transform: translate(2px, 2px);
}

.accodion {
    font-family: var(--font);
    font-size: 14px;
    line-height: 18px;
    padding: 0px 20px 10px;
}

section {
    font-family: var(--font);
    text-align: left;
    padding: inherit;
    margin: inherit;
    line-height: 18px;
}

@media screen and (max-width: 850px) {
    body {
        padding: 10px 5px 25px;
    }

    .page {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        padding: 5px 10px 25px;
    }

    .accodion {
        font-family: var(--font);
        font-size: 14px;
        line-height: 18px;
        padding: 0px 10px 5px;
    }

    .button-74 {
        min-width: 90px;
        padding: 0 20px;
    }
}





